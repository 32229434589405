<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/role/RoleList')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
        <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="选择菜单">
          <el-cascader
            v-model="form.menu_id"
            :options="options"
            :props="{ multiple: true,expandTrigger: 'hover' }"
            collapse-tags
            clearable>
          </el-cascader>
        </el-form-item>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
      <div class="pt20"></div>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="goNav('/role/RoleList')" >取消</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          role_id: 0,
          menu_id: [],
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      let arr = [];
      for(let i in postdata.menu_id){
        arr = arr.concat(postdata.menu_id[i]);
      }
      postdata.menu_id = Array.from(new Set(arr)).toString();
      axios.post(config.bindMenu,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '绑定成功',
                type: 'success'
              });
              _this.goNav('/role/RoleList');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
         let _this = this;
        let obj = JSON.parse(a);
        this.form.role_id = obj.role_id;
        
        axios.get(config.bindMenuList,{params:{role_id:obj.role_id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.menu_id = response.data.data.role_menu;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    initoptions(){
         let _this = this;
        axios.get(config.menu,{params:{page:1}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
         }
         
          _this.options = arr;
        }
      function dg(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].menu_id;
          if(list[i]._child){
            obj.children = dg(list[i]._child);
          }
          arr.push(obj);
        }
        return arr;
      }
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
      this.initoptions();
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
